import React, { useEffect, useRef } from 'react';

const TestDonutChart = ({ xc, yc, r, colorArray }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        function drawHalfCircle(xc, yc, r, startAngle, endAngle, gradientColors) {
            const xStart = xc + Math.cos(startAngle) * r;
            const xEnd = xc + Math.cos(endAngle) * r;
            const yStart = yc + Math.sin(startAngle) * r;
            const yEnd = yc + Math.sin(endAngle) * r;

            const gradient = ctx.createLinearGradient(xStart, yStart, xEnd, yEnd);
            gradient.addColorStop(0, gradientColors[0]);
            gradient.addColorStop(1.0, gradientColors[1]);

            ctx.beginPath();
            ctx.strokeStyle = gradient;
            ctx.arc(xc, yc, r, startAngle, endAngle);
            ctx.lineWidth = 15;
            ctx.stroke();
            ctx.closePath();
        }

        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // First half: using the first color pair from colorArray
        drawHalfCircle(xc, yc, r, -Math.PI / 2, Math.PI / 2, [colorArray[0][0], '#F7F7F7']);
        // Second half: using the second color pair from colorArray
        drawHalfCircle(xc, yc, r, Math.PI / 2, (3 * Math.PI) / 2, [colorArray[1][0], '#F7F7F7']);

        // Get the font family from the body element
        const bodyFontFamily = getComputedStyle(document.body).fontFamily;

        ctx.fillStyle = '#000000';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        // Position the number text slightly above the center and make it larger
        ctx.font = `bold 12px ${bodyFontFamily}`;
        ctx.fillText('55', xc, yc - 10);

        // Position the 'Employee' text slightly below the center
        ctx.font = `9px ${bodyFontFamily}`;
        ctx.fillText('Employee', xc, yc + 5);
    }, [xc, yc, r, colorArray]);

    return (
        <div className="flex justify-center items-center" style={{ width: 90, height: 90 }}>
            <canvas ref={canvasRef} width={90} height={90} />
        </div>
    );
};

export default TestDonutChart;