import React from "react";

function SignSvg({className, color, ...props}) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33331 8.00065V6.16241C3.33331 5.39131 3.33331 5.00576 3.43822 4.69476C3.63781 4.10302 4.10235 3.63848 4.69408 3.43889C5.00509 3.33398 5.39064 3.33398 6.16174 3.33398V3.33398C6.49918 3.33398 6.66791 3.33398 6.82878 3.36038C7.13279 3.41028 7.42116 3.52972 7.67141 3.70941C7.80383 3.8045 7.92313 3.9238 8.16174 4.16241L8.36682 4.36749C8.74159 4.74226 8.92898 4.92965 9.15037 5.05942C9.30876 5.15226 9.47918 5.22285 9.65682 5.2692C9.90512 5.33398 10.1701 5.33398 10.7001 5.33398V5.33398C11.9146 5.33398 12.5218 5.33398 12.9771 5.58893C13.299 5.76918 13.5648 6.03495 13.745 6.35686C14 6.81216 14 7.41938 14 8.63382V9.33398C14 10.5801 14 11.2032 13.732 11.6673C13.5565 11.9714 13.304 12.2238 13 12.3994C12.5359 12.6673 11.9128 12.6673 10.6666 12.6673V12.6673"
        stroke="#FDFDFD"
        strokeWidth="2"
      />
      <path
        d="M2.66669 10.9993H8.66669M8.66669 10.9993L6.33335 8.66602M8.66669 10.9993L6.33335 13.3327"
        stroke="#FDFDFD"
        strokeWidth="2"
      />
    </svg>
  );
}

export default SignSvg;
