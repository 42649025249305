import React, { useEffect, useState } from "react";
import { WidgetCard } from "@bluesilodev/timhutcomponents";

import { useGetEventQuery, useGetBirthdayQuery } from "services/employeeAPI";
import useGetLeaveQuery from "services/leaveAPI";
import CustomCalendar from "components/calendar";
import Tabs from "components/tabs";
import { ProgressBar, ColorLabel } from "utils/charts/chart";
import TestDonutChart from "utils/charts/testChart";

import { ChevronLeft, ChevronRight } from "assets/icons";
import CalendarIcon from 'assets/Calendar.svg';
import EventCalendarIcon from 'assets/eventcalendar.svg';

const AdminDashboardPage = ({ currentUser, setDateData, exampleOptions, day  }) => {
    const stages = [
        { progress: 50, color: '#4BD394' },
        { progress: 12, color: '#EFCA4F' },
        { progress: 12, color: '#B83C3C' },
        { progress: 25, color: '#A3A3AB' },
    ];
    
    return (
        <>
            <div className="flex flex-col mt-4">
                <p className="text-lg font-bold">Welcome Back, {currentUser} </p>
                <p className="text-sm font-bold">Here's what happening today. see the status at once</p>
            </div>
            <div className="flex w-full gap-4 mt-4">
                <div className="w-full grid grid-cols-2 sm:grid-cols-5 gap-2">
                    <WidgetCard
                        radius={"lg"}>
                        <div className="grid grid-cols-1 sm:grid-cols-2 p-1 gap-2">
                            <TestDonutChart xc={45} yc={45} r={35} colorArray={[['#DD7224'], ['#1A1A2E']]} />
                            <div className="flex flex-col gap-1 mt-3" >
                                <div className="gender-label" style={{ position: "relative" }}>
                                    <p className="text-xs font-semibold">70% (37 employee)</p>
                                    <p className="text-xs">Male</p>
                                    <div style={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#1A1A2E', position: "absolute", top: 5, left: -10 }}></div>
                                </div>
                                <div className="gender-label" style={{ position: "relative" }}>
                                    <p className="text-xs font-semibold">30% (37 employee)</p>
                                    <p className="text-xs">Female</p>
                                    <div style={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#DD7224', position: "absolute", top: 5, left: -10 }}></div>
                                </div>
                            </div>
                        </div>
                    </WidgetCard>
                    <WidgetCard
                        radius={"lg"}>
                        <div className="grid grid-cols-1 sm:grid-cols-2 p-1 gap-2">
                            <TestDonutChart xc={45} yc={45} r={35} colorArray={[['#FF52D9'], ['#009CF3']]} />
                            <div className="flex flex-col gap-1 mt-3">
                                <div className="gender-label relative">
                                    <p className="text-xs font-semibold">70% (37 employee)</p>
                                    <p className="text-xs">Male</p>
                                    <div style={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#009CF3', position: "absolute", top: 5, left: -10 }}></div>
                                </div>
                                <div className="gender-label relative">
                                    <p className="text-xs font-semibold">30% (37 employee)</p>
                                    <p className="text-xs">Female</p>
                                    <div style={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#FF52D9', position: "absolute", top: 5, left: -10 }}></div>
                                </div>
                            </div>
                        </div>
                    </WidgetCard>
                    <WidgetCard radius={"lg"}>
                        <div className="flex flex-col items-center justify-center p-4">
                            <div className="border border-2 border-gray-300 rounded-full p-2">
                                <img src={CalendarIcon} alt="Calendar Icon" />
                            </div>
                            <div className="flex flex-col items-center">
                                <p className="input-label mt-2">Employee On Leave</p>
                                <p className="text-md font-semibold">2 Employees</p>
                            </div>
                        </div>
                    </WidgetCard>
                    <WidgetCard
                        radius={"lg"}>
                        <div className="flex flex-col items-center justify-center p-2 sm:p-4">
                            <div className="border border-2 border-gray-300 rounded-full p-2">
                                <img src={CalendarIcon} alt="Calendar Icon" />
                            </div>
                            <div className="flex flex-col items-center">
                                <p className="input-label mt-2">Upcoming Payroll Schedule</p>
                                <p className="text-md font-semibold">1 September 2023</p>
                            </div>
                        </div>
                    </WidgetCard>
                    <WidgetCard className="rounded-lg shadow-xl border-gray-300 border-2 grid col-span-2 sm:col-span-1 ">
                        <div className="flex flex-col items-center justify-center gap-3 p-1">
                            <p className="attendance-label text-xs font-semibold text-center">Weekly Attendance Overview</p>
                            <div className="flex flex-row justify-between w-4/5 items-center">
                                <ChevronLeft className="w-4 h-4" />
                                <p className="date-label text-gray-500 text-xs">19-25 May</p>
                                <ChevronRight color="black" className="w-4 h-4" />
                            </div>
                            <ProgressBar stages={stages} />
                            <ColorLabel />
                        </div>
                    </WidgetCard>
                </div>
            </div>

            <div className="flex flex-col-reverse sm:flex-row w-full mt-4">
                <div className="bg-gray-100 w-full sm:w-3/4">
                    {/* <!-- Your content here --> */}
                </div>

                <div className="flex flex-col w-full sm:!w-1/4">
                    <CustomCalendar setDate={setDateData} />
                    <div className="text-container flex justify-between items-center">
                        <p className="text-label">What's on in August 2023?</p>
                        <button className="bg-white text-black w-10 h-10 flex-shrink-0 m-1 border-none text-2xl">
                            +
                        </button>
                    </div>
                    <Tabs
                        options={exampleOptions}
                        width={95}
                        day={day}
                    />
                    {/* <Tabs options={[
                        { value: "Events", id: "1" },
                        { value: "On Leave", id: "2" },
                        { value: "Birthday", id: "3" }
                    ]} width={80} /> */}
                </div>
            </div>
        </>
    );
}

export default AdminDashboardPage;