
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    punchInTime: '',
    punchOutTime: '',
    breakTime: '',
    returnFromBreakTime: '',
};

const timeSlice = createSlice({
    name: 'times',
    initialState,
    reducers: {
        setPunchInTime: (state, action) => {
            state.punchInTime = action.payload;
        },
        setPunchOutTime: (state, action) => {
            state.punchOutTime = action.payload;
        },
        setBreakTime: (state, action) => {
            state.breakTime = action.payload;
        },
        setReturnFromBreakTime: (state, action) => {
            state.returnFromBreakTime = action.payload;
        },
        resetTimes: (state) => {
            state.punchInTime = '';
            state.punchOutTime = '';
            state.breakTime = '';
            state.returnFromBreakTime = '';
        },
    },
});

export const { setPunchInTime, setPunchOutTime, setBreakTime, setReturnFromBreakTime, resetTimes } = timeSlice.actions;
export default timeSlice.reducer;