import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';

import { Button } from '@bluesilodev/timhutcomponents';

import CameraIcon from 'assets/camera.svg';
import { base64ToFile } from 'utils/utils';


const CameraComponent = ({ setFieldValue, imageName }) => {
    const webcamRef = useRef(null);
    const [photoTaken, setPhotoTaken] = useState(false);
    const [photoDataUri, setPhotoDataUri] = useState('');

    const handleCaptureButtonClick = () => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            setPhotoDataUri(imageSrc);
            setPhotoTaken(true);
            const imageFile = base64ToFile(imageSrc, `${imageName}.jpg`);
            setFieldValue(imageName, imageFile);
        }
    };

    const handleRetakeButtonClick = () => {
        setPhotoDataUri('');
        setPhotoTaken(false);
        setFieldValue('image', '');
    };

    const takeButtonLabel = (
        <div className="flex w-full justify-center gap-2">
            <img src={CameraIcon} alt="Camera Icon" />
            <p className='text-sm'>Take Photo</p>
        </div>
    );

    const retakeButtonLabel = (
        <div className="flex w-full justify-center gap-2">
            <img src={CameraIcon} alt="Camera Icon" />
            <p className='text-sm'>Retake Photo</p>
        </div>
    );

    return (
        <>
            <div className="flex w-full justify-start items-center mb-2">
                <p className='camera-label text-sm font-bold'>Photo Taking</p>
            </div>
            <div className="w-full bg-gray-100 backdrop-blur-md flex flex-col items-center p-4">
                {photoTaken ? (
                    <>
                        <div className="w-full max-w-md flex justify-center items-center mb-4">
                            <img src={photoDataUri} alt="Captured" className="border border-gray-300" />
                        </div>
                        <Button onClick={handleRetakeButtonClick} style="solid" className="h-[50px] w-[250px]" label={retakeButtonLabel} />
                    </>
                ) : (
                    <>
                        <div className="w-full max-w-md flex justify-center items-center mb-4">
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={550}
                                height={300}
                                className="border border-gray-300"
                            />
                        </div>
                        <Button onClick={handleCaptureButtonClick} style="solid" className="h-[50px] w-[250px]" label={takeButtonLabel} />
                    </>
                )}
            </div>
        </>
    );
};

export default CameraComponent;