import React from 'react';

const DataWidget = (attendanceDataStates) => {
    // const { punchInTime, punchOutTime, breakTime, returnFromBreakTime } = attendanceTimeStates.attendanceTimeStates;
    // console.log(attendanceDataStates);
    const { punchInData, punchOutData, breakData, returnBreakData } = attendanceDataStates.attendanceTimeStates;
    // return (
    //     <div className='rounded-lg shadow-xl flex flex-col justify-center border-gray-300 border-2 p-2'>
    //         <div className="flex flex-col gap-1">
    //             <p className="text-xs font-bold">You currently have a shift: Morning Shift</p>
    //             <p className="text-xs">Punch In Time: {punchInTime}</p>
    //             <p className="text-xs">Break: {breakTime}</p>
    //             <p className="text-xs">Resume: {returnFromBreakTime}</p>
    //             <p className="text-xs">Punch Out Time: {punchOutTime}</p>
    //         </div>
    //     </div>
    // );
    return (
        <div className='rounded-lg shadow-xl flex flex-col justify-center border-gray-300 border-2 p-2'>
            <div className="flex flex-col gap-1">
                <p className="text-xs font-bold">You currently have a shift: Morning Shift</p>
                <p className="text-xs">Punch In Time: {punchInData}</p>
                <p className="text-xs">Break: {breakData}</p>
                <p className="text-xs">Resume: {returnBreakData}</p>
                <p className="text-xs">Punch Out Time: {punchOutData}</p>
            </div>
        </div>
    );
};

export default DataWidget;