import React from 'react';
import MapIcon from 'assets/mapicon.svg';


const LocationComponent = ({ address, coords }) => {

    return (
        <div className='flex w-full rounded-md bg-gray-100 border-black border-[1px] gap-2 p-2'>
            <div className="flex items-center">
                <img src={MapIcon} alt="Map Icon" />
            </div>
            <div className="flex flex-col">
                <p className='gps-label text-sm text-gray-600'>Gps Tracking</p>
                {coords ? (
                    <p className='address-label text-sm text-gray-600'>Address: {address}</p>
                ) : (
                    <p className='text-sm text-gray-600'>Getting your location</p>
                )}
            </div>

        </div>
    );
};

export default LocationComponent;