import React from "react";
import PropTypes from "prop-types";

const TestModalDialog = ({
    show,
    className,
    title,
    icon,
    children,
    onClose,
    onSubmit,
    ...props
}) => {
    if (!show) return null;

    return (
        <div className="w-full fixed z-50 inset-0 flex items-center justify-center bg-opacity-50 bg-transparent backdrop-blur-sm">
            <div className="w-full fixed inset-0 bg-[#1414144D]" onClick={onClose}></div>
            <div
                className={`${className} z-[10] bg-white shadow-md rounded-lg flex flex-col`}
                onSubmit={onSubmit}
                role="dialog"
            >
                <div className="flex justify-between items-center mt-[5px] mb-[20px] w-full px-4 pt-3 sticky left-0 top-0">
                    <label className="font-bold text-2xl">{title || "Hello Title"}</label>
                    <div className="flex justify-between items-center gap-2">
                        {icon.map((item, index) => (
                            <button key={index} onClick={onClose} {...props}>
                                {item.icon}
                            </button>
                        ))}
                        <button onClick={onClose} {...props}>
                            <svg width="20" height="20" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 4.5L4 12.5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4 4.5L12 12.5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>
                    </div>
                </div>

                <div className="w-full p-4 overflow-auto max-h-[500px]">{children}</div>
            </div>
        </div>
    );
};

TestModalDialog.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.arrayOf(PropTypes.shape({ icon: PropTypes.node })),
    children: PropTypes.node.isRequired,
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};

TestModalDialog.defaultProps = {
    className: "w-[500px]",
    icon: [],
    children: <React.Fragment></React.Fragment>,
    title: "",
    show: true,
    onClose: () => { },
    onSubmit: () => { },
};

export default TestModalDialog;