import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import { Onboarding, InputText, Button } from "@bluesilodev/timhutcomponents";
import ImgTimhut from "assets/images/logo_login.png";
import { handlePostForgotPassword } from "services/employeeAPI";
import { alertSuccess, alertError } from "utils/alert";


function ForgotPasswordPage() {
    const navigate = useNavigate();

    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
    } = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: yup.object({
            email: yup
                .string()
                .required("Email is Required")
                .email("Invalid Email Format"),
        }),
        onSubmit: async (values) => {
            try {
                const resp = await handlePostForgotPassword(values);
                console.log(resp);
                if (resp && resp.success === true) {
                    alertSuccess('Successfully sent reset password email');
                    navigate("/confirmEmail");
                } else {
                    alertError('Failed to send reset password email');
                }
            } catch (error) {
                alertError('Forgot Password Error: Something went wrong');
            }
        },
    });

    return (
        <>
            <Onboarding source={ImgTimhut}>
                <div className="w-full flex justify-end">
                    <Link to={"/login"}>
                        <Button
                            className={"w-[200px] mt-10 mr-4"}
                            label={
                                <div className="flex items-center gap-3 ">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3.33331 8.00065V6.16241C3.33331 5.39131 3.33331 5.00576 3.43822 4.69476C3.63781 4.10302 4.10235 3.63848 4.69408 3.43889C5.00509 3.33398 5.39064 3.33398 6.16174 3.33398V3.33398C6.49918 3.33398 6.66791 3.33398 6.82878 3.36038C7.13279 3.41028 7.42116 3.52972 7.67141 3.70941C7.80383 3.8045 7.92313 3.9238 8.16174 4.16241L8.36682 4.36749C8.74159 4.74226 8.92898 4.92965 9.15037 5.05942C9.30876 5.15226 9.47918 5.22285 9.65682 5.2692C9.90512 5.33398 10.1701 5.33398 10.7001 5.33398V5.33398C11.9146 5.33398 12.5218 5.33398 12.9771 5.58893C13.299 5.76918 13.5648 6.03495 13.745 6.35686C14 6.81216 14 7.41938 14 8.63382V9.33398C14 10.5801 14 11.2032 13.732 11.6673C13.5565 11.9714 13.304 12.2238 13 12.3994C12.5359 12.6673 11.9128 12.6673 10.6666 12.6673V12.6673"
                                            stroke="#FDFDFD"
                                            strokeWidth="2"
                                        />
                                        <path
                                            d="M2.66669 10.9993H8.66669M8.66669 10.9993L6.33335 8.66602M8.66669 10.9993L6.33335 13.3327"
                                            stroke="#FDFDFD"
                                            strokeWidth="2"
                                        />
                                    </svg>

                                    <h1>Sign In</h1>
                                </div>
                            }
                            type={"button"}
                            style="solid"
                            width={200}
                        />
                    </Link>
                </div>

                <form
                    autoComplete="off"
                    className=" w-full flex flex-col px-5 py-5"
                    onSubmit={handleSubmit}
                >
                    <div className="my-5">
                        <h1 className="font-bold text-2xl mb-5">Forget Password</h1>
                        <p>Enter your registered email address to reset the password</p>
                    </div>
                    <div className="flex flex-col gap-3 w-full">
                        <div>
                            <InputText
                                id="email"
                                title={"Email"}
                                label={null}
                                required={true}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                    <>
                                        {errors.email && touched.email && (
                                            <div className="text-red-500">{errors.email}</div>
                                        )}
                                    </>
                                }
                                icon={
                                    <>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M19.7274 20.4471C19.2716 19.1713 18.2672 18.0439 16.8701 17.2399C15.4729 16.4358 13.7611 16 12 16C10.2389 16 8.52706 16.4358 7.12991 17.2399C5.73276 18.0439 4.72839 19.1713 4.27259 20.4471"
                                                stroke="#1A1A2E"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                            />
                                            <circle
                                                cx="12"
                                                cy="8"
                                                r="4"
                                                stroke="#1A1A2E"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                    </>
                                }
                            />
                        </div>
                    </div>

                    <Button
                        className={"w-full mt-10"}
                        style="solid"
                        type="submit"
                        label={
                            <div className="flex items-center gap-3 ">
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3.33331 8.00065V6.16241C3.33331 5.39131 3.33331 5.00576 3.43822 4.69476C3.63781 4.10302 4.10235 3.63848 4.69408 3.43889C5.00509 3.33398 5.39064 3.33398 6.16174 3.33398V3.33398C6.49918 3.33398 6.66791 3.33398 6.82878 3.36038C7.13279 3.41028 7.42116 3.52972 7.67141 3.70941C7.80383 3.8045 7.92313 3.9238 8.16174 4.16241L8.36682 4.36749C8.74159 4.74226 8.92898 4.92965 9.15037 5.05942C9.30876 5.15226 9.47918 5.22285 9.65682 5.2692C9.90512 5.33398 10.1701 5.33398 10.7001 5.33398V5.33398C11.9146 5.33398 12.5218 5.33398 12.9771 5.58893C13.299 5.76918 13.5648 6.03495 13.745 6.35686C14 6.81216 14 7.41938 14 8.63382V9.33398C14 10.5801 14 11.2032 13.732 11.6673C13.5565 11.9714 13.304 12.2238 13 12.3994C12.5359 12.6673 11.9128 12.6673 10.6666 12.6673V12.6673"
                                        stroke="#FDFDFD"
                                        strokeWidth="2"
                                    />
                                    <path
                                        d="M2.66669 10.9993H8.66669M8.66669 10.9993L6.33335 8.66602M8.66669 10.9993L6.33335 13.3327"
                                        stroke="#FDFDFD"
                                        strokeWidth="2"
                                    />
                                </svg>

                                <h1>Email me a Link to Reset Password</h1>
                            </div>
                        }
                    />
                </form>
            </Onboarding>
        </>
    );
}

export default ForgotPasswordPage;
