import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import TestModalDialog from "components/testModalDialog";

import {
    PunchInWidget,
    PunchOutWidget,
    PunchDisabledWidget,
    BreakWidget,
    ReturnBreakWidget,
    BreakDisabledWidget,
    DataWidget
} from "pages/user/widgets";
import Tabs from "components/tabs";
import PunchInForm from "components/modal/punchInModal";
import PunchOutForm from "components/modal/punchOutModal";
import BreakForm from "components/modal/breakModal";
import ReturnBreakForm from "components/modal/returnBreakModal";
import CustomCalendar from "components/calendar";
import { fetchAndSetWidgets } from "services/attendanceAPI";
import { handleSettingWidgetState } from "utils/utils";
import {
    getInitialIsTimerRunning,
    getInitialTimers,
    getCurrentDate,
    initializeCurrentDate,
    getInitialAttendanceDataStates,
} from "utils/utils";
import { setWidgetState } from "store/reducer/widget";

import { alertSuccess, alertError } from "utils/alert";


const EmployeeDashboardPage = ({ currentUser, setDateData, exampleOptions, day }) => {
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.userData);
    const widgetStates = useSelector(state => state.widgetStateData);
    // const attendanceTimeStates = useSelector(state => state.timeData);
    console.log(exampleOptions);
    console.log('employee day', day);
    console.log('set date function', setDateData);
    const [attendanceDataStates, setAttendanceDataStates] = useState(getInitialAttendanceDataStates);

    const [modalShow, setModalShow] = useState({
        punchModal: false,
        breakModal: false,
        punchOutModal: false,
        returnBreakModal: false
    });
    const [fetchTrigger, setFetchTrigger] = useState(false);

    const [timers, setTimers] = useState(getInitialTimers);
    const [isTimerRunning, setIsTimerRunning] = useState(getInitialIsTimerRunning);

    // const widgets = [
    //     { id: 'punchinwidget', component: PunchInWidget, stateKey: 'showPunchInWidget', modalKey: 'punchModal', timerKey: 'punchWidget' },
    //     { id: 'punchoutwidget', component: PunchOutWidget, stateKey: 'showPunchOutWidget', modalKey: 'punchOutModal', timerKey: 'punchWidget' },
    //     { id: 'punchdisabledwidget', component: PunchDisabledWidget, stateKey: 'showDisablePunchWidget', timerKey: 'punchWidget' },
    //     { id: 'breakwidget', component: BreakWidget, stateKey: 'showBreakWidget', modalKey: 'breakModal', timerKey: 'breakWidget' },
    //     { id: 'returnbreakwidget', component: ReturnBreakWidget, stateKey: 'showReturnBreakWidget', modalKey: 'returnBreakModal', timerKey: 'breakWidget' },
    //     { id: 'breakdisabledwidget', component: BreakDisabledWidget, stateKey: 'showDisableBreakWidget' },
    // ];

    // fetching latest attendance data for updating widget state
    useEffect(() => {
        console.log('useEffect triggered with fetchTrigger:', fetchTrigger);
        // fetchAndSetWidgets(token, dispatch, setIsTimerRunning, setAttendanceDataStates)
        handleFetchAndSetWidget(token)
    }, [fetchTrigger, token]);

    // Reset data for attendance punch times on every new day/date
    useEffect(() => {
        initializeCurrentDate();

        const storedDate = localStorage.getItem('currentDate');
        const currentDate = getCurrentDate();

        if (storedDate !== currentDate) {
            localStorage.setItem('currentDate', currentDate);
            setAttendanceDataStates({
                punchInData: '',
                breakData: '',
                punchOutData: '',
                returnBreakData: ''
            }); // Reset the attendance times in the local state
        }
    }, []);

    // Debounced update timer state to localStorage
    useEffect(() => {
        const timer = setTimeout(() => {
            localStorage.setItem('timers', JSON.stringify(timers));
        }, 500); // Update localStorage every 500ms

        return () => clearTimeout(timer);
    }, [timers]);

    // Persist timer running bool state to localStorage
    useEffect(() => {
        localStorage.setItem('isTimerRunning', JSON.stringify(isTimerRunning));
    }, [isTimerRunning]);

    // Persist attendanceDataStates to localStorage
    useEffect(() => {
        localStorage.setItem('attendanceDataStates', JSON.stringify(attendanceDataStates));
    }, [attendanceDataStates]);

    // Timer running effect
    useEffect(() => {
        const timerIntervals = {};

        if (isTimerRunning.punchWidget) {
            timerIntervals.punchWidget = setInterval(() => {
                setTimers(prevTimers => ({ ...prevTimers, punchWidget: prevTimers.punchWidget + 1 }));
            }, 1000);
        } else {
            setTimers(prevTimers => ({ ...prevTimers, punchWidget: 0 }));
        }

        if (isTimerRunning.breakWidget) {
            timerIntervals.breakWidget = setInterval(() => {
                setTimers(prevTimers => ({ ...prevTimers, breakWidget: prevTimers.breakWidget + 1 }));
            }, 1000);
        } else {
            setTimers(prevTimers => ({ ...prevTimers, breakWidget: 0 }));
        }

        return () => {
            clearInterval(timerIntervals.punchWidget);
            clearInterval(timerIntervals.breakWidget);
        };
    }, [isTimerRunning]);


    const handleOpenModal = (modalKey) => {
        console.log(modalKey);
        setModalShow({
            punchModal: false,
            breakModal: false,
            punchOutModal: false,
            returnBreakModal: false,
            [modalKey]: true,
        });
    };

    const handleCloseModal = () => {
        setModalShow({
            punchModal: false,
            breakModal: false,
            punchOutModal: false,
            returnBreakModal: false,
        });
    };

    const handleFormSubmit = () => {
        // setting fetch trigger state to trigger useeffect for fetching new attendance record
        setFetchTrigger(prev => {
            return !prev;
        });
    };

    // function handling fetching and setting different widget states
    const handleFetchAndSetWidget = async (token) => {
        try {
            const respData = await fetchAndSetWidgets(token);
            const { data } = respData;
            const { latestData, todayData } = data;
            handleSettingWidgetState(todayData, latestData, dispatch, setWidgetState, setIsTimerRunning, setAttendanceDataStates)
            alertSuccess('Success fetching latest attendance data')
        } catch (error) {
            console.error('Error fetching latest attendance data:', error.message || error);
            alertError(error.message || 'Failed to get latest attendance data');
        }
    }


    return (
        <>
            <div className="flex flex-col mt-4">
                <p className="text-lg font-bold">Welcome Back, {currentUser}</p>
                <p className="text-sm font-bold">Cafe {currentUser}</p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-3 mt-4">
                {/* Left side */}
                <div className="col-span-3 flex flex-col gap-2">
                    {/* Widget Card Section */}
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                        <DataWidget attendanceTimeStates={attendanceDataStates} />

                        {widgetStates.showPunchInWidget && (
                            <PunchInWidget handleOpenModal={handleOpenModal} modalKey="punchModal" timer={timers.punchWidget} punchInTime={attendanceDataStates.punchInData} />
                        )}
                        {widgetStates.showPunchOutWidget && (
                            <PunchOutWidget handleOpenModal={handleOpenModal} modalKey="punchOutModal" timer={timers.punchWidget} punchInTime={attendanceDataStates.punchInData} />
                        )}
                        {widgetStates.showDisablePunchWidget && (
                            <PunchDisabledWidget punchInTime={attendanceDataStates.punchInData} />
                        )}
                        {widgetStates.showBreakWidget && (
                            <BreakWidget handleOpenModal={handleOpenModal} modalKey="breakModal" timer={timers.breakWidget} breakTime={attendanceDataStates.breakData} />
                        )}
                        {widgetStates.showReturnBreakWidget && (
                            <ReturnBreakWidget handleOpenModal={handleOpenModal} modalKey="returnBreakModal" timer={timers.breakWidget} breakTime={attendanceDataStates.breakData} />
                        )}
                        {widgetStates.showDisableBreakWidget && (
                            <BreakDisabledWidget breakTime={attendanceDataStates.breakData} />
                        )}
                    </div>

                    {/* Form Modals Section */}
                    {modalShow.punchModal && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                            <TestModalDialog title='Punch In' onClose={handleCloseModal} className={'w-[700px]'}>
                                <PunchInForm onClose={handleCloseModal} handleFormSubmit={handleFormSubmit} />
                            </TestModalDialog>
                        </div>
                    )}

                    {modalShow.breakModal && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                            <TestModalDialog title='Break' onClose={handleCloseModal} className={'w-[700px]'}>
                                <BreakForm onClose={handleCloseModal} handleFormSubmit={handleFormSubmit} />
                            </TestModalDialog>
                        </div>
                    )}
                    {modalShow.punchOutModal && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                            <TestModalDialog title='PunchOut' onClose={handleCloseModal} className={'w-[700px]'}>
                                <PunchOutForm onClose={handleCloseModal} handleFormSubmit={handleFormSubmit} />
                            </TestModalDialog>
                        </div>
                    )}
                    {modalShow.returnBreakModal && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                            <TestModalDialog title='Return Break' onClose={handleCloseModal} className={'w-[700px]'}>
                                <ReturnBreakForm onClose={handleCloseModal} handleFormSubmit={handleFormSubmit} />
                            </TestModalDialog>
                        </div>
                    )}
                </div>

                {/* Right side */}
                <div className="w-[500px] sm:col-span-1 sm:ml-4 flex flex-col">
                    <CustomCalendar setDate={setDateData} />
                    <div className="text-container flex justify-between items-center">
                        <p className="text-label">What's on in August 2023?</p>
                        <button className="bg-white text-black w-10 h-10 flex-shrink-0 m-1 border-none text-2xl">+</button>
                    </div>
                    <Tabs
                        options={exampleOptions}
                        width={95}
                        day={day}
                    />
                </div>
            </div>

        </>
    );
}

export default EmployeeDashboardPage;