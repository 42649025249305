import PunchOutIcon from 'assets/icon/PunchOutSVG';

const PunchDisabledWidget = ({ punchInTime }) => {
    const formattedPunchInTime = punchInTime ? punchInTime : "No Punch In Time";
    return (
        <div className='rounded-lg shadow-xl flex items-center justify-between relative border-gray-300 border-2 p-2 gap-2 cursor-not-allowed'>
            <div className="left-container flex flex-col gap-2">
                <p className="text-xs font-bold">Punch-in to shift</p>
                <p className="text-xs">{formattedPunchInTime}</p>
                <p className="text-lg font-bold">-- : -- : --</p>
                <p className="text-xs">Morning Shift</p>
            </div>
            <div className="right-container mr-8">
                <PunchOutIcon />
            </div>
            <div className="absolute inset-0 bg-gray-100 opacity-50 rounded-lg"></div>
        </div>

    )
}

export default PunchDisabledWidget;