import React from "react";
import PropTypes from "prop-types";

const ChevronSVG = ({ direction, color, className, onClick, width, height }) => {
    const isLeft = direction === "left";
    const pathData = isLeft
        ? "M15.75 19.5 8.25 12l7.5-7.5"
        : "m8.25 4.5 7.5 7.5-7.5 7.5";

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke={color}
            className={className}
            onClick={onClick}
            width={width}
            height={height}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d={pathData}
            />
        </svg>
    );
};

ChevronSVG.propTypes = {
    direction: PropTypes.oneOf(["left", "right"]).isRequired,
    color: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ChevronSVG.defaultProps = {
    color: "#111111",
    className: "",
    onClick: () => { },
    width: "20", // Default width
    height: "20", // Default height
};

export default ChevronSVG;