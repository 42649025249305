import { useQuery } from 'react-query';

import { customAxios } from "utils/axios";

const useGetLeaveQuery = () => {
    return useQuery(['getLeave'], async () => {
        const { data } = await customAxios.get(`/api/leave/thisMonth`);
        return data;
    });
};

export default useGetLeaveQuery;