import GoBreakIcon from "assets/icon/GoBreakSVG";
import { formatTime } from "utils/utils";

const BreakWidget = ({ handleOpenModal, modalKey, timer, breakTime }) => {
    const formattedBreakTime = breakTime ? breakTime : "No Break Time";
    return (
        <div className="rounded-lg shadow-xl border-gray-300 border-2 flex items-center justify-between p-2 gap-2 cursor-pointer" onClick={() => handleOpenModal(modalKey)}>
            <div className="left-container flex flex-col gap-2">
                <p className="text-xs font-bold">Go For Break</p>
                <p className="text-xs">{formattedBreakTime}</p>
                <p className="text-lg font-bold">{formatTime(timer)}</p>
                <p className="text-xs">Morning Shift</p>
            </div>
            <div className="right-container mr-8">
                <GoBreakIcon />
            </div>
        </div>
    )
}

export default BreakWidget;