import React from "react";

function WidgetSvg({ color, className, ...props }) {
  return (
    <svg
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      {...props}
    >
      <path
        d="M1.83337 8.00008C1.83337 6.75393 1.83337 6.13085 2.10132 5.66675C2.27686 5.36271 2.52934 5.11023 2.83337 4.9347C3.29748 4.66675 3.92055 4.66675 5.16671 4.66675H11.8334C13.0795 4.66675 13.7026 4.66675 14.1667 4.9347C14.4707 5.11023 14.7232 5.36271 14.8988 5.66675C15.1667 6.13085 15.1667 6.75393 15.1667 8.00008C15.1667 9.24624 15.1667 9.86931 14.8988 10.3334C14.7232 10.6375 14.4707 10.8899 14.1667 11.0655C13.7026 11.3334 13.0795 11.3334 11.8334 11.3334H5.16671C3.92055 11.3334 3.29748 11.3334 2.83337 11.0655C2.52934 10.8899 2.27686 10.6375 2.10132 10.3334C1.83337 9.86931 1.83337 9.24624 1.83337 8.00008Z"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M13.1667 2.66659C13.1667 1.93021 12.5698 1.33325 11.8334 1.33325H5.16671C4.43033 1.33325 3.83337 1.93021 3.83337 2.66659"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M13.1667 13.3333C13.1667 14.0696 12.5698 14.6666 11.8334 14.6666H5.16671C4.43033 14.6666 3.83337 14.0696 3.83337 13.3333"
        stroke={color}
        strokeWidth="2"
      />
      <rect
        x="4.16671"
        y="7.00008"
        width="3.33333"
        height="0.666667"
        rx="0.333333"
        stroke={color}
        strokeWidth="0.666667"
      />
    </svg>
  );
}

export default WidgetSvg;
