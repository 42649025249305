import { useQuery } from 'react-query';
import { customAxios } from 'utils/axios';

const useGetAppQuery = () => {
  return useQuery(['apps'], async () => {
    const { data } = await customAxios.get(`/api/common/app`);
    return data;
  });
};

const useGetCSCQuery = () => {
  return useQuery('getCountry', async () => {
    const response = await customAxios.get('/api/common/csc');
    return response.data;
  });
};

export {
  useGetAppQuery,
  useGetCSCQuery,
};