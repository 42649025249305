import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useGeolocated } from 'react-geolocated';
import axios from 'axios';
import {
    Button,
    TextArea,
    InputSelect
} from '@bluesilodev/timhutcomponents';

import CameraComponent from 'components/camera';
import LocationComponent from 'components/locationTracking';
import { handlePostBreak } from 'services/attendanceAPI';
import { processTimeData } from 'utils/utils';
import { setBreakTime } from 'store/reducer/time';
import { extractPostalCode } from 'utils/utils';

import { useDispatch } from 'react-redux';

import CloseIcon from 'assets/close.svg';
import TimeIcon from 'assets/time.svg';

import { validationSchema } from './schema';
import { alertSuccess, alertError } from 'utils/alert';

const BreakForm = ({ onClose, handleFormSubmit }) => {
    const [locationData, setLocationData] = useState({});
    const dispatch = useDispatch();
    const { coords } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
        },
        userDecisionTimeout: 5000,
    });

    useEffect(() => {
        if (coords) {
            const fetchAddress = async () => {
                const { latitude, longitude } = coords;
                const apiKey = process.env.REACT_APP_TOM_TOM_API_KEY;
                const url = `https://api.tomtom.com/search/2/reverseGeocode/${latitude},${longitude}.json?key=${apiKey}`;

                try {
                    const response = await axios.get(url);
                    const addressData = response.data.addresses[0].address;
                    const address = addressData.freeformAddress;
                    const postalCode = addressData.postalCode || extractPostalCode(address);
                    setLocationData({
                        address: address,
                        lat: latitude,
                        long: longitude,
                        postalCode: postalCode,
                    });
                } catch (error) {
                    console.error('Error fetching address:', error);
                }
            };

            fetchAddress();
        }
    }, [coords]);

    // const { mutate, isLoading } = usePostBreakDataMutation(handleFormSubmit, onClose, dispatch);

    const buttonLabel = (
        <div className="flex w-full justify-center gap-2">
            <img src={TimeIcon} alt="Time Icon" />
            <p className="text-sm">Go Break</p>
        </div>
    );
    const cancellabel = (
        <div className="flex w-full justify-center gap-2">
            <img src={CloseIcon} alt="Close Icon" />
            <p className="text-sm">Cancel</p>
        </div>
    )

    return (
        <Formik
            initialValues={{ shift: 'Morning Shift', locations: 'Cafe Halim', jobPositions: "Cafe Halim", breakDesc: '' }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                try {
                    const formData = new FormData();
                    for (const key in values) {
                        if (key.includes('breakDesc') || key.includes('breakImage')) {
                            formData.append(key, values[key]);
                        }
                    }

                    // Log form data contents
                    for (const pair of formData.entries()) {
                        console.log(`${pair[0]}: ${pair[1]}`);
                    }
                    const resp = await handlePostBreak(formData);
                    if (resp.data && resp.data.break.breakTime) {
                        const formattedBreakTime = processTimeData(resp.data.break.breakTime);
                        dispatch(setBreakTime(formattedBreakTime));
                        console.log(`Formatted Break Time: ${formattedBreakTime}`);
                    }
                    alertSuccess('Success Sending Employee Break Data');
                    handleFormSubmit();
                    onClose();

                } catch (error) {
                    console.error('Error during break submission:', error);
                    alertError('Failed to send Employee Break Data');
                }
            }}
        >
            {({ handleChange, values, errors, touched, setFieldValue }) => (
                <Form>
                    <div className="form-group grid grid-cols-2 gap-6 p-3">
                        <div className="col-span-1">
                            <InputSelect
                                name="shift"
                                title="Shift"
                                options={[
                                    { label: 'Morning Shift', value: 'Morning Shift' },
                                    { label: 'Night Shift', value: 'Night Shift' },
                                ]}
                                className="h-[20px]"
                                required
                                value={values.shift}
                                onChange={handleChange}
                                error={touched.shift && errors.shift ? errors.shift : ''}
                            />
                        </div>
                        <div className="col-span-1"></div>
                        <div className="col-span-1">
                            <InputSelect
                                name="locations"
                                title="Locations"
                                options={[
                                    { label: 'Cafe Halim', value: 'Cafe Halim' },
                                    { label: 'Cafe Halim#2', value: 'Cafe Halim#2' },
                                ]}
                                className="h-[20px]"
                                required
                                value={values.locations}
                                onChange={handleChange}
                                error={touched.locations && errors.locations ? errors.locations : ''}
                            />
                        </div>
                        <div className="col-span-1">
                            <InputSelect
                                name="jobPositions"
                                title="Job Positions"
                                options={[{ label: 'Cafe Halim', value: 'Cafe Halim' }]}
                                className="h-[20px]"
                                required
                                value={values.jobPositions}
                                onChange={handleChange}
                                error={touched.jobPositions && errors.jobPositions ? errors.jobPositions : ''}
                            />
                        </div>
                        <div className="col-span-2">
                            <LocationComponent address={locationData.address} coords={coords} />
                        </div>
                        <div className="col-span-2 row-span-3">
                            <CameraComponent setFieldValue={setFieldValue} imageName='breakImage' />
                        </div>
                    </div>
                    <div className="col-span-2 mt-4">
                        <TextArea
                            name="breakDesc"
                            label="Description"
                            rows={4}
                            value={values.breakDesc}
                            onChange={handleChange}
                            error={touched.breakDesc && errors.breakDesc ? errors.breakDesc : ''}
                        />
                    </div>
                    <div className="w-full grid grid-cols-2 gap-6 mt-4">
                        <Button onClick={onClose} type="button" className="h-[50px]" label={cancellabel} />
                        <Button type="submit" style="solid" className="h-[50px]" label={buttonLabel} />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default BreakForm;