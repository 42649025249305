import * as yup from 'yup'

export const loginSchema = yup.object({
    email: yup
        .string()
        .required("Email is Required")
        .email("Invalid Email Format"),
    password: yup
        .string()
        .required("Password is Required")
        .min(6, "Minimum 6 Character")
});
