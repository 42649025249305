import PunchIcon from 'assets/icon/PunchSVG';
import { formatTime } from "utils/utils";

const PunchInWidget = ({ handleOpenModal, modalKey, timer, punchInTime }) => {
    const formattedPunchInTime = punchInTime ? punchInTime : "No Punch In Time";
    return (
        <div className='rounded-lg shadow-xl flex items-center justify-between border-gray-300 border-2 p-2 gap-2 cursor-pointer' onClick={() => handleOpenModal(modalKey)}>
            <div className="left-container flex flex-col gap-2">
                <p className="text-xs font-bold">Punch-in to shift</p>
                <p className="text-xs">{formattedPunchInTime}</p>
                <p className="text-lg font-bold">{formatTime(timer)}</p>
                <p className="text-xs">Morning Shift</p>
            </div>
            <div className="right-container mr-8">
                <PunchIcon />
            </div>
        </div>
    )
}

export default PunchInWidget;