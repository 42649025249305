import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showPunchInWidget: true,
    showPunchOutWidget: false,
    showDisablePunchWidget: false,
    showDisableBreakWidget: true,
    showBreakWidget: false,
    showReturnBreakWidget: false,
};

const widgetSlice = createSlice({
    name: 'widgets',
    initialState,
    reducers: {
        setWidgetState: (state, action) => {
            const { widgetKeys, value } = action.payload;
            // Set all keys to false
            Object.keys(state).forEach(key => {
                state[key] = false;
            });
            // Set specified keys to true
            widgetKeys.forEach(key => {
                state[key] = value;
            });
        },
        setMultipleWidgetStates: (state, action) => {
            action.payload.forEach(({ widgetKey, value }) => {
                state[widgetKey] = value;
            });
        },
    },
});

export const { setWidgetState, setMultipleWidgetStates } = widgetSlice.actions;
export default widgetSlice.reducer;